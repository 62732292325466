<!--
 * @Description: 属性配置
 * @Author: WuPuyi
 * @LastEditors: ChenXueLin
 * @Date: 2021-04-19 11:33:49
 * @LastEditTime: 2022-06-14 13:42:55
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <el-form ref="form" :model="formData" label-width="120px">
            <!-- 属性配置 start -->
            <template>
              <div class="edit-title">属性配置</div>
              <div class="switch-page-button">
                <el-button
                  round
                  :class="[clickIndex == index ? 'activeButton' : '']"
                  v-for="(btn, index) in btnList"
                  :key="index"
                  @click="switchPage(index)"
                  >{{ btn.combinationName }}</el-button
                >
              </div>
            </template>
            <!-- 属性配置 end -->

            <!-- 终端属性信息 start -->
            <template>
              <div class="edit-title">终端属性信息</div>
              <div class="edit-content">
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="电子锁" prop="islock">
                      <el-radio-group v-model="formData.islock">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="门磁" prop="ishavelock">
                      <el-radio-group v-model="formData.ishavelock">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="摄像头" prop="iscamera">
                      <el-radio-group v-model="formData.iscamera">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="冷机" prop="cooler">
                      <el-radio-group v-model="formData.cooler">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="液压" prop="hydraulic">
                      <el-radio-group v-model="formData.hydraulic">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="油控" prop="oilcontrol">
                      <el-radio-group v-model="formData.oilcontrol">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="空调" prop="aircondition">
                      <el-radio-group v-model="formData.aircondition">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="中控锁" prop="centerlock">
                      <el-radio-group v-model="formData.centerlock">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="断油断电" prop="cutoilelec">
                      <el-radio-group v-model="formData.cutoilelec">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="尾板控制" prop="isman">
                      <el-radio-group v-model="formData.isman">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="后备电源" prop="isstandbypower">
                      <el-radio-group v-model="formData.isstandbypower">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="二氧化碳控制" prop="co2Ctrl">
                      <el-radio-group v-model="formData.co2Ctrl">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="搅拌机" prop="ismixer">
                      <el-radio-group v-model="formData.ismixer">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="双速度里程表" prop="dobulespeed">
                      <el-radio-group v-model="formData.dobulespeed">
                        <el-radio :label="1">码表</el-radio>
                        <el-radio :label="2">GPS</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="监听功能" prop="isMonitor">
                      <el-radio-group v-model="formData.isMonitor">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="LCD" prop="lcd">
                      <el-radio-group v-model="formData.lcd">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="3G视频" prop="video3g">
                      <el-radio-group v-model="formData.video3g">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="头架分离" prop="isSeparation">
                      <el-radio-group v-model="formData.isSeparation">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="是否挂车车头" prop="istrailer">
                      <el-radio-group v-model="formData.istrailer">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="是否ADAS" prop="isAdas">
                      <el-radio-group v-model="formData.isAdas">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="胎压" prop="isTyrebar">
                      <el-radio-group v-model="formData.isTyrebar">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="载重" prop="extAttr2">
                      <el-radio-group v-model="formData.extAttr2">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="视频录音" prop="extAttr3">
                      <el-radio-group v-model="formData.extAttr3">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="对讲设备" prop="extAttr4">
                      <el-radio-group v-model="formData.extAttr4">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </template>
            <!-- 终端属性信息 end -->

            <!-- 其他属性配置 start -->
            <template>
              <div class="edit-title">其他属性配置</div>
              <div class="edit-content">
                <el-row>
                  <el-col :span="8">
                    <el-form-item
                      label="电源接入方式"
                      class="form-item--1"
                      prop="power"
                    >
                      <e6-vr-select
                        v-model="formData.power"
                        :loading="powerAccessModeLoading"
                        :data="powerAccessModeList"
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                        placeholder="电源接入方式"
                        clearable
                      ></e6-vr-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="开关数量"
                      class="form-item--1"
                      prop="switchnum"
                    >
                      <e6-vr-select
                        v-model="formData.switchnum"
                        :loading="switchNumTypeLoading"
                        :data="switchNumTypeList"
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                        placeholder="开关数量"
                        clearable
                      ></e6-vr-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="设备来源"
                      class="form-item--1"
                      prop="eqsourceid"
                    >
                      <e6-vr-select
                        v-model="formData.eqsourceid"
                        :loading="equipSourceLoading"
                        :data="equipSourceList"
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                        placeholder="设备来源"
                        clearable
                      ></e6-vr-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="GPS天线位置"
                      class="form-item--1"
                      prop="antennalocation"
                    >
                      <e6-vr-select
                        v-model="formData.antennalocation"
                        :loading="gpsAntennaLocationLoading"
                        :data="gpsAntennaLocationList"
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                        placeholder="GPS天线位置"
                        clearable
                      ></e6-vr-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="主机版本"
                      class="form-item--1"
                      prop="mainversion"
                    >
                      <el-input
                        placeholder="主机版本"
                        v-model="formData.mainversion"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="LCD版本"
                      class="form-item--1"
                      prop="lcdversion"
                    >
                      <el-input
                        placeholder="LCD版本"
                        v-model="formData.lcdversion"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="拍照分辨率"
                      class="form-item--1"
                      prop="photoResolution"
                    >
                      <e6-vr-select
                        v-model="formData.photoResolution"
                        :loading="photoResolutionCodeLoading"
                        :data="photoResolutionCodeList"
                        :props="{
                          id: 'codeValue',
                          label: 'codeName'
                        }"
                        placeholder="拍照分辨率"
                        clearable
                      ></e6-vr-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </template>
            <!-- 其他属性配置 end -->

            <!-- 线路信息 start -->
            <template>
              <div class="edit-title">线路信息</div>
              <div class="edit-content">
                <el-form-item label="门磁">
                  <el-checkbox-group v-model="formData.mcChannelList">
                    <el-checkbox :label="1">一路</el-checkbox>
                    <el-checkbox :label="2">二路</el-checkbox>
                    <el-checkbox :label="3">三路</el-checkbox>
                    <el-checkbox :label="4">四路</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="继电器">
                  <el-checkbox-group v-model="formData.relayChannelList">
                    <el-checkbox :label="1">一路</el-checkbox>
                    <el-checkbox :label="2">二路</el-checkbox>
                    <el-checkbox :label="3">三路</el-checkbox>
                    <el-checkbox :label="4">四路</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="湿度">
                  <el-checkbox-group v-model="formData.humidityChannelList">
                    <el-checkbox :label="1">一路</el-checkbox>
                    <el-checkbox :label="2">二路</el-checkbox>
                    <el-checkbox :label="3">三路</el-checkbox>
                    <el-checkbox :label="4">四路</el-checkbox>
                    <el-checkbox :label="5">五路</el-checkbox>
                    <el-checkbox :label="6">六路</el-checkbox>
                    <el-checkbox :label="7">七路</el-checkbox>
                    <el-checkbox :label="8">八路</el-checkbox>
                    <el-checkbox :label="9">九路</el-checkbox>
                    <el-checkbox :label="10">十路</el-checkbox>
                    <el-checkbox :label="11">十一路</el-checkbox>
                    <el-checkbox :label="12">十二路</el-checkbox>
                    <el-checkbox :label="13">十三路</el-checkbox>
                    <el-checkbox :label="14">十四路</el-checkbox>
                    <el-checkbox :label="15">十五路</el-checkbox>
                    <el-checkbox :label="16">十六路</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="温控">
                  <el-checkbox-group v-model="formData.temperaturectrlList">
                    <el-checkbox :label="1">一路</el-checkbox>
                    <el-checkbox :label="2">二路</el-checkbox>
                    <el-checkbox :label="3">三路</el-checkbox>
                    <el-checkbox :label="4">四路</el-checkbox>
                    <el-checkbox :label="5">五路</el-checkbox>
                    <el-checkbox :label="6">六路</el-checkbox>
                    <el-checkbox :label="7">七路</el-checkbox>
                    <el-checkbox :label="8">八路</el-checkbox>
                    <el-checkbox :label="9">九路</el-checkbox>
                    <el-checkbox :label="10">十路</el-checkbox>
                    <el-checkbox :label="11">十一路</el-checkbox>
                    <el-checkbox :label="12">十二路</el-checkbox>
                    <el-checkbox :label="13">十三路</el-checkbox>
                    <el-checkbox :label="14">十四路</el-checkbox>
                    <el-checkbox :label="15">十五路</el-checkbox>
                    <el-checkbox :label="16">十六路</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>

                <el-form-item label="摄像头">
                  <el-checkbox-group v-model="formData.cameraChannelList">
                    <el-checkbox :label="1">一路</el-checkbox>
                    <el-checkbox :label="2">二路</el-checkbox>
                    <el-checkbox :label="3">三路</el-checkbox>
                    <el-checkbox :label="4">四路</el-checkbox>
                    <el-checkbox :label="5">五路</el-checkbox>
                    <el-checkbox :label="6">六路</el-checkbox>
                    <el-checkbox :label="7">七路</el-checkbox>
                    <el-checkbox :label="8">八路</el-checkbox>
                    <el-checkbox :label="9">九路</el-checkbox>
                    <el-checkbox :label="10">十路</el-checkbox>
                    <el-checkbox :label="11">十一路</el-checkbox>
                    <el-checkbox :label="12">十二路</el-checkbox>
                    <el-checkbox :label="13">十三路</el-checkbox>
                    <el-checkbox :label="14">十四路</el-checkbox>
                    <el-checkbox :label="15">十五路</el-checkbox>
                    <el-checkbox :label="16">十六路</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <!-- TODO: 传感器应用后续添加 -->
                <el-form-item label="传感器应用">
                  <el-checkbox-group v-model="formData.appidList">
                    <el-checkbox
                      :label="item.id"
                      v-for="item in sensorAppList"
                      :key="item.id"
                      >{{ item.appName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </template>
            <!-- 线路信息 end -->
          </el-form>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack(false)"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="handleSaveAdd"
            v-if="itemStatus != 8"
            >确定</el-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import goBack from "@/mixins/goBack";
import {
  getCombination,
  getAttr,
  findDownList,
  getSensorList,
  updateHostAttribute
} from "@/api";
export default {
  name: "attributeConfig",
  mixins: [base, goBack],
  data() {
    return {
      btnList: [],
      clickIndex: 0,
      itemStatus: "",
      formData: {
        thirdClassId: "",
        appidList: [],
        /*** 终端属性信息 ***/
        islock: 0, // 电子锁
        ishavelock: 0, // 门磁
        iscamera: 0, // 摄像头
        cooler: 0, // 冷机
        hydraulic: 0, // 液压
        oilcontrol: 0, // 油控制
        aircondition: 0, // 空调
        centerlock: 0, // 中控锁
        cutoilelec: 0, // 断电断油
        isman: 0, // 尾板控制
        isstandbypower: 0, // 后备电源
        co2Ctrl: 0, // 二氧化碳控制
        ismixer: 0, // 搅拌机
        dobulespeed: 1, // 双速度里程表
        isMonitor: 0, // 监听功能
        lcd: 0, // LCD
        video3g: 0, // 3G视频
        isSeparation: 0, // 头架分离
        istrailer: 0, // 是否挂车车头
        isAdas: 0, // 是否ADAS
        isTyrebar: 0, // 胎压
        extAttr2: 0, // 载重
        extAttr3: 0, // 视频录音
        extAttr4: 0, // 对讲设备
        /*** 其他属性配置 ***/
        power: "", // 电源接入方式
        switchnum: "", // 开关数量
        eqsourceid: "", // 设备来源
        antennalocation: "", // GPS 天线位置
        mainversion: "", // 主机版本
        lcdversion: "", // LCD 版本
        photoResolution: "", // 拍照分辨率
        /*** 线路信息 ***/
        mcChannel: "", // 门磁
        mcChannelList: [],
        humidityChannel: "", // 温度
        humidityChannelList: [],
        temperaturectrl: "", // 温控
        temperaturectrlList: [],
        relayChannel: "", // 继电器
        relayChannelList: [],
        cameraChannel: "", // 摄像头
        cameraChannelList: [],
        remark: "" // 备注
      },
      powerAccessModeLoading: false,
      sensorAppList: [], //传感器列表
      terminalHostAttribute: {}, // 便携设备
      // 电源接入方式
      powerAccessModeList: [],
      // 开关数量
      switchNumTypeLoading: false,
      switchNumTypeList: [],
      // GPS天线位置
      gpsAntennaLocationLoading: false,
      gpsAntennaLocationList: [],
      // 拍照分辨率
      photoResolutionCodeLoading: false,
      photoResolutionCodeList: [],
      // 设备来源
      equipSourceLoading: false,
      equipSourceList: []
    };
  },
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.itemId = hasParamsRoute.params.itemId;
    this.itemStatus = hasParamsRoute.params.itemStatus;
    if (this.itemId) {
      this.getCombinationReq();
    }
  },
  methods: {
    async initData() {
      try {
        this.equipThirdListLoading = true;
        this.powerAccessModeLoading = true;
        this.gpsAntennaLocationLoading = true;
        this.photoResolutionCodeLoading = true;
        this.switchNumTypeLoading = true;
        let promiseList = [
          findDownList([
            "powerAccessMode",
            "switchNumType",
            "gpsAntennaLocation",
            "photoResolution",
            "protocolTypeCode"
          ]),
          getSensorList({
            appName: "",
            equThirdTypeId: this.btnList[this.clickIndex].thirdClassId,
            sensors: ""
          })
        ];
        let [selectRes, sensorRes] = await Promise.all(promiseList);
        //电源接入方式
        this.powerAccessModeList = this.getFreezeResponse(selectRes, {
          path: "data.powerAccessMode"
        });
        //开关数量
        this.switchNumTypeList = this.getFreezeResponse(selectRes, {
          path: "data.switchNumType"
        });
        //Gps天线位置
        this.gpsAntennaLocationList = this.getFreezeResponse(selectRes, {
          path: "data.gpsAntennaLocation"
        });
        //拍照分辨率
        this.photoResolutionCodeList = this.getFreezeResponse(selectRes, {
          path: "data.photoResolution"
        });
        //设备来源
        this.equipSourceList = this.getFreezeResponse(selectRes, {
          path: "data.protocolTypeCode"
        });
        //传感器列表
        this.sensorAppList = this.getFreezeResponse(sensorRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.equipThirdListLoading = false;
        this.gpsAntennaLocationLoading = false;
        this.powerAccessModeLoading = false;
        this.photoResolutionCodeLoading = false;
        this.switchNumTypeLoading = false;
      }
    },
    //获取所有组合
    async getCombinationReq() {
      try {
        let res = await getCombination({
          itemId: this.itemId
        });
        this.btnList = res.data.combinationRespVOS;
        this.getAttrReq();
        this.initData();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取属性默认值
    async getAttrReq() {
      try {
        let res = await getAttr({
          id: this.btnList[this.clickIndex].terminalId
        });
        let data = res.data;
        data = JSON.parse(JSON.stringify(data));
        data.eqsourceid += "";
        data.power += "";
        data.switchnum += "";
        data.antennalocation += "";
        data.photoResolution += "";

        this.formData = data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击筛选框
    switchPage(index) {
      this.clickIndex = index;
    },
    // 更新设备属性
    async updateHostAttribute() {
      try {
        this.loading = true;
        let res = await updateHostAttribute({
          combinationReqVOList: [
            {
              combinationId: this.btnList[this.clickIndex].combinationId,
              terminalId: this.btnList[this.clickIndex].terminalId,
              attrReqVO: this.formData
            }
          ]
        });
        if (res.code == "OK") {
          this.$message.success("保存成功");
          this.closeTag(this.$route);
          this.handleGoBack(true);
        }
      } catch (err) {
        printError(err);
      } finally {
        this.loading = false;
      }
    },
    // 取消
    // handleGoBack(refresh) {
    //   this.closeTag(this.$route);
    //   this.routerPush({
    //     name: "taskListManage/ActionItemDetail",
    //     params: {
    //       refresh: refresh ? refresh : false
    //     }
    //   });
    // },
    // 提交
    handleSaveAdd() {
      this.updateHostAttribute();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-wrapper__body {
  .edit-content {
    padding: 20px 20px 2px;
  }
  .form-item--1 {
    .el-form-item__content {
      width: 260px;
      .e6-vr-select,
      .el-input {
        width: 260px;
      }
    }
  }
  .form-item--2 {
    .el-form-item__content {
      width: 520px;
      .el-input {
        width: 520px;
      }
    }
  }
  .device-name {
    .e6-vr-select {
      width: 220px;
    }
  }
}
</style>
