/*
 * @Description: 可配置的路由回跳（返回）逻辑，用于独立的详情页面较多
 * @Author: LiangYiNing
 * @Date: 2021-12-17 14:53:55
 * @LastEditTime: 2022-04-22 13:37:29
 * @LastEditors: LiangYiNing
 */

import { typeOf } from "@/utils/util";
export default {
  data() {
    return {
      goBackOptions: {
        needCloseTag: true, //返回是是否需要关闭当前tab
        goBackRouteName: null, //配置的返回路由名称，若不配置则返回$route.params.origin的路由
        params: null //放回时路由所带参数
      }
    };
  },
  computed: {
    // 源页面完整url - "取消"按钮跳转使用
    originRouteName: vm => {
      if (vm.$route.params.origin) {
        return vm.$route.params.origin;
      }
      let hasParamsRoute = vm.getHasParamsRoute();
      return hasParamsRoute.params.origin;
    }
  },
  watch: {},
  mounted() {},
  methods: {
    // 返回上一页
    handleGoBack(refresh = false) {
      if (typeOf(refresh) !== "Boolean") {
        refresh = false;
      }
      //关闭当前Tag
      if (this.goBackOptions.needCloseTag) this.closeTag(this.$route);
      const name = this.goBackRouteName || this.originRouteName;
      if (name) {
        this.routerPush({
          name,
          params: {
            refresh,
            ...(this.goBackOptions.params || {})
          }
        });
        return;
      }
      this.$router.go(-1);
      // if (this.visitedViews.length) {
      //   const latestView = this.visitedViews.slice(-1)[0];
      //   this.$router.push(latestView);
      //   return;
      // }
      // this.$message.warning("当前没有可以跳转的页面！");
    }
  }
};
